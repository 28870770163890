<script>
import { fetchPageBuilder } from '@drapejs/core';
import * as sales from "@/commands/sales";
import useWebsiteTexts from "@/composables/useWebsiteTexts";
import UnifaunCountries from "./UnifaunCountries";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  components: {
    UnifaunCountries,
  },
  data() {
    return {
      selectedCountry: null,
      email: "",
      zip: "",
      countryRequired: false,
      emailRequired: false,
      zipRequired: false,
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    customerCountry() {
      if(!this.currentCountryName) {
        return null;
      }

      return {
        id: this.$cart.countryId,
        name: this.currentCountryName
      }
    },
    customerEmail() {
      return this.$cart.customerAddress.email;
    },
    customerZip() {
      return this.$cart.customerAddress.postalCode;
    },
    availableCountries() {
      return this.$countrySwitcher?.availableCountries || [];
    },
    hasManyCountries() {
      return false;
    },
    currentCountryName() {
      var currentCountries = this.availableCountries.filter(
        (c) => c.countryId == this.$cart.countryId
      );

      return currentCountries?.length ? currentCountries[0]?.name : "";
    },
    channelCountries() {
      return this.availableCountries
        .filter((c) => c.channelSystemId == this.$channel.systemId)
        .map((c) => ({
          id: c.countryId,
          name: c.name,
        }));
    },
    countryPlaceholder() {
      return this.countryRequired
        ? this.websiteText("deliverycheckout__deliverycountryrequired").value
        : this.websiteText("deliverycheckout__deliverycountry").value;
    },
    emailPlaceholder() {
      return this.emailRequired
        ? this.websiteText("deliverycheckout__mailaddressrequired").value
        : this.websiteText("deliverycheckout__mailaddress").value;
    },
    zipPlaceholder() {
      return this.zipRequired
        ? this.websiteText("deliverycheckout__zipcoderequired").value
        : this.websiteText("deliverycheckout__zipcode").value;
    },
  },
  watch: {
    selectedCountry() {
      this.countryRequired = false;
    },
  },
  methods: {
    validateEmail(email) {
      return emailRegex.test(email);
    },
    async onKeyUp(e) {
      if (e.keyCode === 13) {
        await this.submitAddress();
      }
    },
    async submitAddress() {
      this.countryRequired = !this.selectedCountry;
      this.emailRequired = !this.validateEmail(this.email);
      this.zipRequired = !this.zip;

      if (this.hasManyCountries && !this.selectedCountry) {
        return;
      }

      if (this.emailRequired || this.zipRequired) {
        return;
      }

      var country = this.hasManyCountries
        ? this.selectedCountry.id
        : this.$cart.countryId;

      const request = {
        ...this.buildRequest(),
        country: country,
        zip: this.zip,
        email: this.email,
      };

      await this.$invoke(sales.commands.setAddressFromKlarna, request);

      this.$emit("address-input", {
        country: country,
        email: this.email,
        zip: this.zip,
      });
    },
    buildRequest() {
      return fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
        },
        ""
      );
    },
  },
  mounted() {
    this.selectedCountry = this.customerCountry;
    this.email = this.customerEmail || "";
    this.zip = this.customerZip || "";
  },
};
</script>
<template>
  <div class="unifaun-delivery-address">
    <div class="unifaun-delivery-address__info">
      {{ websiteText("deliverycheckout__yourinformation").value }}
    </div>
    <div
      class="unifaun-delivery-address__info-address"
      v-if="!hasManyCountries"
    >
      {{
        websiteText("deliverycheckout__yourinformationaddress", {
          country: currentCountryName,
        }).value
      }}
    </div>

    <div
      class="
        unifaun-delivery-address__field-container
        unifaun-delivery-address__field-container-country
      "
      :class="{
        'unifaun-delivery-address__field-container-required': countryRequired,
      }"
      v-if="hasManyCountries"
    >
      <div class="unifaun-delivery-address__field-text-wrapper">
        <unifaun-countries
          :class="{
            'unifaun-delivery-address__field-input-required': countryRequired,
          }"
          :placeholder="countryPlaceholder"
          :options="channelCountries"
          v-model="selectedCountry"
        >
        </unifaun-countries>
      </div>
    </div>

    <div class="unifaun-delivery-address__fields">
      <div
        class="unifaun-delivery-address__field-container"
        :class="{
          'unifaun-delivery-address__field-container-required': emailRequired,
          'unifaun-delivery-address__field-container-email': !hasManyCountries,
          'unifaun-delivery-address__field-container-email-country':
            hasManyCountries,
        }"
      >
        <div class="unifaun-delivery-address__field-text-wrapper">
          <input
            type="email"
            name="email"
            autocomplete="email"
            class="unifaun-delivery-address__field-input"
            :placeholder="emailPlaceholder"
            v-model="email"
            :class="{
              'unifaun-delivery-address__field-input-required': emailRequired,
            }"
            @focus="emailRequired = false"
            @keyup="onKeyUp"
          />
        </div>
      </div>

      <div
        class="unifaun-delivery-address__field-container"
        :class="{
          'unifaun-delivery-address__field-container-required': zipRequired,
          'unifaun-delivery-address__field-container-zip': !hasManyCountries,
          'unifaun-delivery-address__field-container-zip-country':
            hasManyCountries,
        }"
      >
        <div class="unifaun-delivery-address__field-text-wrapper">
          <input
            type="text"
            name="postal-code"
            autocomplete="postal-code"
            class="unifaun-delivery-address__field-input"
            :placeholder="zipPlaceholder"
            v-model="zip"
            :class="{
              'unifaun-delivery-address__field-input-required': zipRequired,
            }"
            @focus="zipRequired = false"
            @keyup="onKeyUp"
          />
        </div>
      </div>
    </div>

    <div class="unifaun-delivery-address__button-container">
      <button class="primary-button unifaun-delivery-address__button" @click="submitAddress">
        {{ websiteText("deliverycheckout__continue").value }}
      </button>
    </div>
  </div>
</template>

<style>
.unifaun-delivery-address {
  margin: auto;
  padding: 0 1rem;
}

.unifaun-delivery-address__info {
  font-family: var(--font-proxima-nova-semi-bold);
  display: block;
  margin: 0 0 1rem;
}

.unifaun-delivery-address__info-address {
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-grey330);
  margin: 0.25rem 0;
}

.unifaun-delivery-address__fields {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  flex-shrink: 0;
  border-style: solid;
  border-width: 0;
}

.unifaun-delivery-address__field-container {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  justify-content: space-between;
  padding: 0 15px;
  background-color: var(--color-white);
  border-color: rgb(150, 147, 145);
}

.unifaun-delivery-address__field-container-country {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-width: 0px;
}

.unifaun-delivery-address__field-container-email {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right-width: 0px;
  width: 50%;
}

.unifaun-delivery-address__field-container-email-country {
  border-bottom-left-radius: 5px;
  border-right-width: 0px;
  width: 50%;
}

.unifaun-delivery-address__field-container-zip {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 50%;
}

.unifaun-delivery-address__field-container-zip-country {
  border-bottom-right-radius: 5px;
  width: 50%;
}

.unifaun-delivery-address__field-text-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 0;
  top: -16px;
  width: 100%;
}

input[type="text"].unifaun-delivery-address__field-input {
  border: none;
  padding: 0;
}

input[type="email"].unifaun-delivery-address__field-input {
  border: none;
  padding: 0;
}

.unifaun-delivery-address__field-input {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  outline: currentcolor none medium;
  width: 100%;
  box-shadow: none;
  caret-color: rgb(72, 123, 148);
  color: rgb(23, 23, 23);
  -webkit-text-fill-color: rgb(23, 23, 23);
  opacity: 1;
  font-family: var(--font-body);
  font-weight: 500;
  font-size: 16px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -1px;
  white-space: nowrap;
  text-rendering: geometricprecision;
}

.unifaun-delivery-address__field-container-required {
  border-color: var(--color-red);
  background-color: rgb(255, 246, 245);
}

.unifaun-delivery-address__field-container-required
  .unifaun-country__placeholder {
  -webkit-text-fill-color: var(--color-red);
  color: var(--color-red);
  opacity: 1;
}

.unifaun-delivery-address__field-input-required {
  -webkit-text-fill-color: var(--color-red);
  color: var(--color-red);
  opacity: 1;
}

.unifaun-delivery-address__field-input-required::placeholder {
  -webkit-text-fill-color: var(--color-red);
  color: var(--color-red);
  opacity: 1;
}

.unifaun-delivery-address__field-input-required:-ms-input-placeholder {
  color: var(--color-red);
  opacity: 1;
}

.unifaun-delivery-address__field-input-required::-ms-input-placeholder {
  color: var(--color-red);
  opacity: 1;
}

.unifaun-delivery-address__button-container {
  text-align: right;
  margin-top: 16px;
}

.unifaun-delivery-address__button {
  height: 35px;
  padding: 0 16px;
}

@media(--tablet){
  .unifaun-delivery-address {
    padding: 0 1.25rem;
  }

  .unifaun-delivery-address__info {
    margin: 0.25rem 0 1rem;
  }
}

@media(--desktop){
  .unifaun-delivery-address {
    padding: 0 2.25rem;
  }

  .unifaun-delivery-address__info {
    margin: 1.25rem 0 1rem;
  }
}
</style>
