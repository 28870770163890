<script>
import { litiumBaseUrl } from "@/config";
import useWebsiteTexts from "@/composables/useWebsiteTexts";
import UnifaunDeliveryOption from "./UnifaunDeliveryOption";
import ProgressIndicator from "../ProgressIndicator";

export default {
  components: {
    UnifaunDeliveryOption,
    ProgressIndicator,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    zip: {
      type: String,
      required: true,
    },
    delivery: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      internalZip: '',
      unifaunData: [],
      selectedOption: null,
      isLoading: true,
      noOptionsReturned: false,
      previousLoadCountry: '',
      previousLoadZip: '',
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();

    return {
      websiteText,
    };
  },
  computed: {
    hasOptions() {
      return (
        this.unifaunData.DeliveryOptions &&
        this.unifaunData.DeliveryOptions.length > 0
      );
    },
    deliveryOptions() {
      if (!this.hasOptions) {
        return [];
      }

      return this.unifaunData.DeliveryOptions.map((o) =>
        o.SubOptions && o.SubOptions.length > 0 ? o.SubOptions : [o]
      ).reduce((acc, val) => acc.concat(val), []);
    },
    grandTotal() {
      return this.$cart.grandTotal || 0;
    },
    isSubscribedToNewsletter() {
      return this.$cart.newsletterConsent?.agreedToConsent || false;
    },    
    klarnaZipCode() {
      return this.$cart?.customerAddress?.postalCode;
    },
    normalizedLitiumBaseUrl() {
      let result = litiumBaseUrl;
      if (litiumBaseUrl[litiumBaseUrl.length - 1] === "/") {
        result = result.substring(0, result.length - 1);
      }

      return result;
    },    
    deliveries() {
      return this.$cart.deliveries || [];
    },
    hasMultipleDeliveries() {
      return this.deliveries.length > 1;
    },
    discountCode() {
      return this.$cart.discountCode || '';
    }
  },
  methods: {
    async loadOptions(force) {
      if ((!this.country || !this.internalZip) && !force) {
        return;
      }

      if (!force) {
        this.previousLoadCountry = this.country;
        this.previousLoadZip = this.internalZip;
      }

      const deliveryCountry = this.delivery.inventoryZip ? this.delivery.inventoryCountry || '' : '';

      var data = {
        Language: this.$channel.locale,
        Currency: this.$cart.currency.id,
        ChannelId: this.$channel.systemId,
        FromCountry: deliveryCountry,
        FromZipCode: this.delivery.inventoryZip || '',
        CartPrice: this.delivery.total,
        Weight: this.delivery.weight,
        Volume: this.delivery.volume,
        ToCountry: (this.country || this.previousLoadCountry).toLowerCase(),
        ToZipCode: this.internalZip || this.previousLoadZip,
        CustomParameters: {
          isSplit: this.hasMultipleDeliveries ? 1 : 0,
          deliveryNo: this.index + 1, 
          fromwarehouse: this.delivery.inventoryId
        },
      };

      this.isLoading = true;
      this.noOptionsReturned = false;

      const litium = await this.$getItem("__litium");
      var response = await fetch(
        `${this.normalizedLitiumBaseUrl}/distancify/api/unifaun/get-options`,
        {
          method: "POST",
          body: JSON.stringify(data),
          credentials: "include",
          headers: {
            "LH-Session": litium?.session || "",
            "Content-Type": "application/json"
          },
        }
      )
      
      this.unifaunData = await response.json();

      if (this.unifaunData?.DeliveryOptions?.length === 0) {
        this.noOptionsReturned = true;
        this.$emit("no-options", this.delivery.id);
        this.isLoading = false;

        await this.$setItem('__deliveryOptionId', '');

        return;
      }

      if (!this.delivery.selectedDeliveryOption && this.deliveryOptions?.[0]) {
        this.selectedOption = this.deliveryOptions?.[0];
      } else {
        var optionToSelect = this.deliveryOptions?.find(
          (d) => d?.Id === this.delivery.selectedDeliveryOption
        );
        if (optionToSelect) {
          this.emitOptionSet(optionToSelect);
        }

        this.selectedOption = optionToSelect || this.deliveryOptions?.[0];
        await this.$setItem('__deliveryOptionId', this.selectedOption?.Id || '');
      }

      this.isLoading = false;
    },
    emitOptionSet(optionSet) {
      this.$emit("option-set", this.delivery.id, optionSet);
    },
  },
  watch: {
    zip(val, oldVal) {
      if (val && val !== oldVal) {
        this.internalZip = val;
      }
    },
    klarnaZipCode(val, oldVal) {
      if (val && val !== oldVal) {
        this.internalZip = val;
      }
    },
    internalZip(val, oldVal) {
      if (val && val !== oldVal) {
        this.loadOptions();
      }
    },
    grandTotal(val, oldVal) {
      if (val !== oldVal) {
        this.loadOptions();
      }
    },
    isSubscribedToNewsletter(val, oldVal) {
      if (val !== oldVal) {
        this.loadOptions();
      }      
    },
    hasMultipleDeliveries(val, oldVal) {
      if (val !== oldVal) {
        this.loadOptions();
      }
    },
    discountCode(val, oldVal) {
      if (val !== oldVal) {
        //There is a scenario where Klarna widget loads and clears zip code
        this.loadOptions(true);
      }
    },
  },
  async mounted() {
    this.internalZip = this.zip;
    await this.loadOptions();
  },
};
</script>
<template>
  <div>
    <div v-if="hasOptions || isLoading" class="unifaun-delivery-options-container">
      <progress-indicator :isLoading="isLoading" />
      <div v-if="hasOptions" class="unifaun-delivery-options">
        <div v-for="(option, index) in deliveryOptions" :key="option.Id">
          <div class="unifaun-delivery-options__separator" v-if="index > 0" />
          <unifaun-delivery-option
            :display-value="option"
            :delivery="delivery"
            v-model="selectedOption"
            @option-set="emitOptionSet"
          />
        </div>
      </div>
    </div>
    <div v-if="noOptionsReturned">
      {{ websiteText("deliverycheckout__noshippingoptions").value }}
    </div>
  </div>
</template>

<style>
.unifaun-delivery-options-container {
  position: relative;
  min-height: 40px;
}

.unifaun-delivery-options__separator {
  height: 1px;
  margin: 0 19px;
  background-color: var(--color-neutral-05);
}
</style>
